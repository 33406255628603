export const osmosisVideoUrl = 'https://www.osmosis.org';

// eslint-disable-next-line max-len
export const TEMP_USER_CHAT_ID = 7777777777;
export const TEMP_BOT_CHAT_ID = 8888888888;
export const ADMIN_MODAL_ID = 'ADMIN_MODAL_ID';

export enum ChatConnectionProtocol {
  HTTP_STREAM = 'HTTP_STREAM',
  WEB_SOCKETS = 'WEB_SOCKETS',
  HTTP_POST = 'HTTP_POST',
}

export const MAX_PROMPT_LENGTH = 2500;
export const MAX_PROMPT_LENGTH_DISPLAY = '2,500';
export const SHERPATH_AI_EBOOK_CONTENT_ISBNS = [
  '9780323828116',
  '9780323792332',
  '9780443108686',
  '9780323932974',
  '9780323884198',
  '9780323812160',
  '9780323697095',
  '9780323827997',
  '9780323931274',
  '9780323811798',
  '9780323825856',
  '9780323830874',
  '9780323776905',
  '9780323761864',
  '9780323846387',
  '9780323697903',
  '9780323763202',
  '9780323811484',
  '9780323683210',
  '9780323793179',
  '9780323811682',
  '9780323811729',
  '9780323598132'
];
